import { Link } from "react-router-dom";
import logo from "../images/logo.webp";
import useTracking from "../services/useTracking";

export default function Header() {
  const tracking = useTracking("Header");

  return (
    <div className="header">
      <div className="header-left">
        <Link
          to="/"
          onClick={() => {
            tracking("Click on logo from Header");
          }}
        >
          <img className="header-logo" src={logo} alt="Logo"></img>
          <span className="header-logo-text">AI Therapy Chat</span>
        </Link>
      </div>

      <div className="header-center"></div>

      <div className="header-right"></div>
    </div>
  );
}
