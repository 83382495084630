import { Link } from "react-router-dom";
import logo from "../images/logo.webp";
import useTracking from "../services/useTracking";

// Context
import { useAuth } from "../context/useAuth";

export default function Header() {
  const tracking = useTracking("Header");
  const { logout, user } = useAuth();

  return (
    <div className="header">
      <div className="header-left">
        <Link
          to="/"
          onClick={() => {
            tracking("Click on logo from Header");
          }}
        >
          <img className="header-logo" src={logo} alt="Logo"></img>
          <span className="header-logo-text">aitc</span>
        </Link>
      </div>

      <div className="header-center"></div>

      <div className="header-right">
        {user ? (
          <div className="header-btn">
            <a href="/" onClick={logout}>
              Logout
            </a>
          </div>
        ) : (
          <div className="header-btn">
            <Link
              to="/submit"
              onClick={() => {
                tracking("Click on submit product from Header");
              }}
            >
              Submit your eco product
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
