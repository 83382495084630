import { useState, useRef } from "react";
import Share from "../components/Share";
import api from "../services/api";
import MDEditor from "@uiw/react-md-editor";

export default function EditProdModal({ product, close }) {
  let [prod, setProd] = useState(product);
  let [loading, setLoading] = useState(false);
  const inputFile = useRef(null);

  const setValue = (key, value) => {
    let newObj = {};
    newObj[key] = value;
    setProd({ ...prod, ...newObj });
  };

  const save = async () => {
    if (!prod.tags) prod.tags = [];
    let prodObj = { ...prod, ...{ tags: prod.tags.split ? prod.tags.split(",") : prod.tags } };
    let data = await api.saveProd(prodObj);
    if (data.status === 200) {
      close(data.data);
    }
  };

  const reprashe = async () => {
    setLoading(true);
    let data = await api.reprashe(prod.longDesc);
    setProd({ ...prod, ...{ longDesc: data.data.text } });
    setLoading(false);
  };

  const selectPhoto = async () => {
    inputFile.current.click();
  };

  const uploadPhoto = async (e) => {
    let img = await api.uploadPhoto(e.target.files[0], { type: "product", name: prod.name });
    if (img.status === 200) {
      setValue("image", img.data.fileName);
    } else {
      alert("Error in the upload");
      inputFile.current.value = null;
      inputFile.current.file = null;
    }
  };

  return (
    <>
      <div className="share-modal-backdrop">
        <div className="share-modal">
          <button className="close-btn" onClick={close}>
            X
          </button>

          <div className="modal-content">
            <div className="btn-bar">
              <div onClick={save} className="button small">
                Save
              </div>

              {loading ? (
                <div className="button small">Loading</div>
              ) : (
                <div onClick={reprashe} className="button small">
                  Reprashe by AI
                </div>
              )}
              <Share product={prod}></Share>

              {product._id && (
                <div onClick={() => selectPhoto()} className="button small">
                  Change image
                </div>
              )}
              <input type="file" ref={inputFile} onChange={uploadPhoto} style={{ display: "none" }} />
            </div>

            <div>
              <div className="label-row">
                <label>Name</label>
                <input value={prod.name} onChange={(e) => setValue("name", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>URL</label>
                <input value={prod.url} onChange={(e) => setValue("url", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>Description</label>
                <input value={prod.description} onChange={(e) => setValue("description", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>Tags</label>
                <input value={prod.tags} onChange={(e) => setValue("tags", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>Enabled</label>
                <input type="checkbox" checked={prod.enabled} onChange={(e) => setValue("enabled", e.target.checked)}></input>
              </div>
              <div className="label-row">
                <label>Long Description</label>
                <div className="container-markdown">
                  <MDEditor height="400px" value={prod.longDesc} onChange={(e) => setValue("longDesc", e)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
