import useTracking from "../services/useTracking";

export default function Footer() {
  const tracking = useTracking("Footer");

  return (
    <footer className="border-t" sectioncomponent="MixoFooter">
      <div className="mx-auto max-w-7xl overflow-hidden py-16 px-6 sm:py-24 lg:px-8 space-y-8">
        {/* <!-- Links --><!-- Socials --> */}
        <p className="text-center text-xs leading-5 text-gray-500">© 2023. All rights reserved.</p>
      </div>
    </footer>
  );
}
