import React from "react";
import Routes from "./Routes";
import ReactGA from "react-ga4";

// Context
import { ProvideAuth } from "./context/useAuth";
import { BrowserRouter } from "react-router-dom";
import { ProvideMessage } from "./services/useMessages";
import { Providesocket } from "./services/useSocket";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function App() {
  return (
    <div className="main-container">
      <ProvideAuth>
        <Providesocket>
          <ProvideMessage>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </ProvideMessage>
        </Providesocket>
      </ProvideAuth>
    </div>
  );
}

export default App;
