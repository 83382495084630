import { create } from "apisauce";
import packageJson from "../../package.json";

export const createApi = () => {
  const api = create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      "Cache-Control": "no-cache",
      "app-version": packageJson.version,
    },
    timeout: 30000,
  });

  const setToken = (token) => {
    if (token) {
      const headerPrefix = "Bearer ";
      const header = "Authorization";
      api.addRequestTransform((request) => {
        request.headers[header] = `${headerPrefix}${token}`;
        return request;
      });
    }
  };

  const login = (username, password) => {
    return api.post(`/api/adminUsers/login`, { username, password });
  };

  const getUser = () => {
    return api.get(`/api/adminUsers/getUser`, {});
  };

  const sendMessage = (message, userId) => {
    return api.post(`/api/messages/send`, { message, userId });
  };

  // return functions for interface
  return {
    setToken,
    login,
    getUser,
    sendMessage,
  };
};

// Creates a singleton
export default createApi();
