import React, { useState, useContext, createContext, useEffect } from "react";

// APIs
import api from "../services/api";
import { useSocket } from "./useSocket";
import { useAuth } from "../context/useAuth";

export const messageContext = createContext();

function useMessageProvider() {
  let socketContext = useSocket();
  let auth = useAuth();
  const [answering, setAnswering] = useState(false);

  useEffect(() => {
    // api.loadConversation();
    if (socketContext.socket) {
      // console.log("attach answer handler");

      socketContext.socket.on("answerSent", (msg) => {
        setMessages((messages) => {
          let newList = messages.concat([msg]);
          return newList;
        });
        setAnswering(false);
      });
    }
  }, [socketContext.socket]);

  const [messages, setMessages] = useState([
    {
      from: "AI",
      message: "How can I support you today?",
      date: new Date(),
    },
  ]);

  const sendMessage = async (msg) => {
    let res = await api.sendMessage(msg, auth.userId);
    const newListRequest = messages.concat([res.data]);
    setMessages(newListRequest);
    setAnswering(true);
  };

  return {
    messages,
    setMessages,
    sendMessage,
    answering,
  };
}

export function ProvideMessage({ children }) {
  const values = useMessageProvider();
  return <messageContext.Provider value={values}>{children}</messageContext.Provider>;
}

export const useMessage = () => {
  return useContext(messageContext);
};
