export const getUrl = function (prod) {
  return process.env.REACT_APP_SHORT_URL + "/p/" + prod.token + "/" + slug(prod.name);
};

function slug(str) {
  return str
    ? str
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-")
    : "share";
}
