import { useEffect, useRef, useState } from "react";
import api from "../services/api";
import useTracking from "../services/useTracking";

// Imgs
import header from "../images/header.webp";

// Components
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { useMessage } from "../services/useMessages";

export default function Home() {
  const chatRef = useRef();
  const messageRef = useRef();
  const messageContext = useMessage();

  useEffect(() => {
    if (messageContext.messages && messageContext.messages.length > 1) {
      const lastChildElement = chatRef.current?.lastElementChild;
      lastChildElement?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageContext.messages]);

  const sendMessage = async () => {
    await messageContext.sendMessage(messageRef.current.value);
    messageRef.current.value = "";
  };

  return (
    <>
      <Helmet>
        <meta property="og:url" content="https://aitherapychat.com/" />
        <meta property="og:title" content="AITherapyChat " />
        <meta property="og:site_name" content="aitc" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="." />
        <meta property="og:image" content="https://aitherapychat.com/logo.webp" />
        <meta property="og:image:alt" content="AI Therapy Chat" />
        <meta property="twitter:url" content="https://aitherapychat.com/" />
        <meta property="twitter:title" content="AI Therapy Chat" />
        <meta property="twitter:description" content="." />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image:src" content="https://aitherapychat.com/logo.png" />
      </Helmet>
      <Header></Header>
      <div className="main-content">
        <h1>Talk with me</h1>

        <div className="chatbox-content">
          <div className="chat-content" ref={chatRef}>
            {messageContext.messages.map((msg, i) => (
              <div key={i} className={"msg-content msg-from-" + msg.from}>
                <div className="msg-text">{msg.message}</div>
              </div>
            ))}
            <div></div>
          </div>

          {messageContext.answering && <div className="writing">I'm writing...</div>}

          <div className="control-content">
            <input onKeyDown={(e) => e.keyCode == 13 && sendMessage()} type="text" className="message" ref={messageRef}></input>
            <button onClick={sendMessage} className="button black">
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
