import { useEffect, useState } from "react";
import api from "../services/api";
import useTracking from "../services/useTracking";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Share from "../components/Share";
import { getUrl } from "../services/utils";

export default function Products() {
  const [products, setProducts] = useState([]);
  const tracking = useTracking("Products List");

  useEffect(() => {
    fetchProds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProds = async () => {
    const res = await api.getProducts();
    setProducts(res.data);
  };

  return (
    <>
      <Header></Header>
      <div className="products-list-page">
        <h1>Our products</h1>

        <p>Share our products on your socials to give them visibility</p>
        <p>For each buy from our affiliate marketing link we make a donation to ocean-cleaning projects</p>

        {products.map((prod, i) => (
          <div className="product-container" key={i}>
            <img className="product-image" src={prod.image} alt={prod.name} />
            <div className="product-body">
              <Link
                to={"/products/" + prod._id}
                className="product-name"
                onClick={() => tracking("Click details link", "Prod " + prod._id)}
              >
                {prod.name}
              </Link>
              <div className="product-description">{prod.description}</div>

              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href={getUrl(prod)}
                referrerPolicy="no-referrer-when-downgrade"
                target="_blank"
                className="button small product-link"
                onClick={() => tracking("Click buy link", "Prod " + prod._id, prod)}
              >
                Buy from official store
              </a>
              <Share product={prod}></Share>

              <div className="tags-container">
                {prod.tags.map((tag, i) => (
                  <div className="tag" key={i}>
                    {tag}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}

        <div style={{ textAlign: "center", marginBottom: 30 }}>
          <Link to="/submit">
            <button className="button black">Submit your eco-friendly product</button>
          </Link>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
