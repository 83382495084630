import { useEffect, useState, useRef } from "react";
import api from "../services/api";
import { Helmet } from "react-helmet";
import HeaderAdmin from "../components/HeaderAdmin";
import EditProdModal from "../components/EditProdModal";
import StatsProdModal from "../components/StatsProdModal";
import { getUrl } from "../services/utils";
import UploadModal from "../components/UploadModal";
import Papa from "papaparse";

const TITLE = "aitc - Admin";

export default function Submit() {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState();

  const [showEditModal, setShowEditModal] = useState();
  const [showStatsModal, setShowStatsModal] = useState();
  const [time, setTime] = useState(new Date().getTime());

  const [preview, setPreview] = useState();
  const [success, setSuccess] = useState();
  const [errors, setErrors] = useState([]);
  const inputFile = useRef(null);
  const fileReader = new FileReader();

  useEffect(() => {
    fetchProd();
  }, []);

  const fetchProd = async () => {
    let prod = await api.getProductsAdmin();
    setProducts(prod.data);
  };

  const generateSitemap = () => {
    api.generateSitemap();
  };

  const addProd = () => {
    setProduct({});
    setShowEditModal(true);
  };

  const editProd = (prod) => {
    setProduct(prod);
    setShowEditModal(true);
  };

  const viewStats = (prod) => {
    setProduct(prod);
    setShowStatsModal(true);
  };

  const closeModalStats = (prod) => {
    setShowStatsModal(false);
  };

  const closeModal = (prod) => {
    setShowEditModal(false);
    fetchProd();
    setTime(new Date().getTime());
  };

  const upload = (e) => {
    e.preventDefault();

    let file = e.target.files[0];
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        let array = Papa.parse(text, {
          header: true,
          skipEmptyLines: true,
          delimitersToGuess: [";", ",", "\t", "|"],
        });
        inputFile.current.value = null;
        setPreview(array.data);
      };

      fileReader.readAsText(file);
    }
  };

  const closeModalUpload = (result) => {
    if (result.insert) {
      setSuccess(result.insert.length + " order succesfully imported");
      setErrors(result.errors);
      fetchProd();
    }
    setPreview(null);
  };

  const selectCsv = async () => {
    inputFile.current.click();
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="admin-container">
        <h2>Admin</h2>

        <div className="modal-content">
          <div className="btn-bar">
            <div onClick={addProd} className="button small">
              Add product
            </div>
            <div onClick={() => selectCsv()} className="button small black">
              Import CSV
            </div>
            <div onClick={() => generateSitemap()} className="button small black">
              Generate Sitemap
            </div>
          </div>
        </div>

        <input type="file" accept=".csv" ref={inputFile} onChange={upload} style={{ display: "none" }} />
        {preview && <UploadModal data={preview} close={closeModalUpload}></UploadModal>}

        {success && <div className="alert-success">{success}</div>}
        {errors.map((err, i) => (
          <div key={i} className="alert-error" dangerouslySetInnerHTML={{ __html: err }}></div>
        ))}

        <table>
          <thead>
            <tr>
              <td>Image</td>
              <td>Enabled</td>
              <td className="product-views">Clicks</td>
              <td className="product-views">Name</td>
              <td>URLs</td>
            </tr>
          </thead>
          <tbody>
            {products.map((prod, i) => (
              <tr key={i}>
                <td>{prod.image && <img className="product-image" alt={prod.name} src={prod.image + "?t=" + time} />}</td>
                <td className="product-views">
                  <input type="checkbox" checked={prod.enabled} disabled></input>
                </td>
                <td className="product-views" onClick={(e) => viewStats(prod)}>
                  {/* eslint-disable-next-line */}
                  <a href="#">{prod.visit ? prod.visit.length : 0}</a>
                </td>
                <td className="product-name" onClick={(e) => editProd(prod)}>
                  {/* eslint-disable-next-line */}
                  <a href="#">{prod.name}</a>
                </td>
                <td className="product-url">
                  {/* eslint-disable-next-line */}
                  <div>
                    <a rel="noreferrer" href={getUrl(prod)} target="_blank" referrerPolicy="no-referrer-when-downgrade">
                      {getUrl(prod)}
                    </a>
                  </div>
                  <div>
                    <a rel="noreferrer" href={prod.url} target="_blank">
                      {prod.url}
                    </a>
                  </div>
                  <div>
                    <a rel="noreferrer" href={process.env.REACT_APP_APP_URL + "/products/" + prod._id} target="_blank">
                      {process.env.REACT_APP_APP_URL}/products/{prod._id}
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showEditModal && <EditProdModal product={product} close={closeModal}></EditProdModal>}
        {showStatsModal && <StatsProdModal product={product} close={closeModalStats}></StatsProdModal>}
      </div>
    </>
  );
}
