import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// Pages
import Home from "./pages/Home";
import Products from "./pages/Products";
import ProductDetail from "./pages/ProductDetail";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import ReactGA from "react-ga4";
import Privacy from "./pages/Privacy";

// Context
import { useAuth } from "./context/useAuth";

export default function RoutesApp() {
  let auth = useAuth();
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send("pageview");
    window.pintrk("track", "pagevisit");
  }, [location.pathname]);

  useEffect(() => {
    auth.checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {auth.user ? <Route path="/admin" element={<Admin />} /> : <Route path="/admin" element={<Login />} />}
      <Route path="/login" element={<Login />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:id" element={<ProductDetail />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
}
