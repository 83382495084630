import React, { useState, useContext, createContext, useEffect } from "react";
import { io } from "socket.io-client";

// APIs
import api from "./api";
import { useMessage } from "./useMessages";
import { useAuth } from "../context/useAuth";

export const socketContext = createContext();

function useSocketProvider() {
  let auth = useAuth();
  let messagesContex = useMessage();

  const [socket, setSocket] = useState();

  useEffect(() => {
    // Start
    console.log("Start socket " + process.env.REACT_APP_SOCKET_URL + "/" + auth.userId);
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      extraHeaders: {
        Authorization: auth.userId,
      },
    });
    setSocket(socket);
    // // send a message to the server

    // // receive a message from the server
    // socket.on("hello from server", (...args) => {
    //   // ...
    // });
  }, []);

  const emit = (type, msg) => {
    socket.emit({ type, msg });
  };

  return {
    socket,
    emit,
  };
}

export function Providesocket({ children }) {
  const values = useSocketProvider();
  return <socketContext.Provider value={values}>{children}</socketContext.Provider>;
}

export const useSocket = () => {
  return useContext(socketContext);
};
